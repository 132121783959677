@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Bold.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Bold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Bold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-BoldItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-BoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLight-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLight-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLight-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLight-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLight-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-ExtraLightItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-ExtraLightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Mono ExtraLight Italic"), local("IBMPlexMono-ExtraLightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ExtraLightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Italic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Light.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-LightItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-LightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Medium.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Medium-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Medium-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-MediumItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-MediumItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-MediumItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-MediumItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-MediumItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-MediumItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-MediumItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Regular.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-SemiBoldItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-SemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-SemiBoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Text.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Text-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Text-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Mono Text"), local("IBMPlexMono-Text"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-TextItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-TextItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-TextItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-TextItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-TextItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-TextItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Mono Text Italic"), local("IBMPlexMono-TextItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-TextItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Thin.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Thin-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Thin-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Thin-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-Thin-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-ThinItalic.woff2") format("woff2"), url("../IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-ThinItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ThinItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ThinItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ThinItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ThinItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"), url("../IBM-Plex-Mono/fonts/split/woff2/IBMPlexMono-ThinItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Bold.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Bold-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-BoldItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-BoldItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLight-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-ExtraLightItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-ExtraLightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ExtraLightItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Italic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Italic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Light.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Light-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-LightItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-LightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-LightItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Medium.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Medium-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-MediumItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-MediumItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-MediumItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Regular.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Regular-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBold-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-SemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-SemiBoldItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Text.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Text-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-TextItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-TextItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-TextItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Thin.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-Thin-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-ThinItalic.woff2") format("woff2"), url("../IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-ThinItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../IBM-Plex-Sans/fonts/split/woff2/IBMPlexSans-ThinItalic-Greek.woff2") format("woff2");
  unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold"), local("IBMPlexSansCondensed-Bold"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Bold.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold"), local("IBMPlexSansCondensed-Bold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Bold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold"), local("IBMPlexSansCondensed-Bold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold"), local("IBMPlexSansCondensed-Bold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold"), local("IBMPlexSansCondensed-Bold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold Italic"), local("IBMPlexSansCondensed-BoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-BoldItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold Italic"), local("IBMPlexSansCondensed-BoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold Italic"), local("IBMPlexSansCondensed-BoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold Italic"), local("IBMPlexSansCondensed-BoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Sans Condensed Bold Italic"), local("IBMPlexSansCondensed-BoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight"), local("IBMPlexSansCondensed-ExtraLight"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight"), local("IBMPlexSansCondensed-ExtraLight"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLight-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight"), local("IBMPlexSansCondensed-ExtraLight"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLight-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight"), local("IBMPlexSansCondensed-ExtraLight"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLight-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight"), local("IBMPlexSansCondensed-ExtraLight"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLight-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight Italic"), local("IBMPlexSansCondensed-ExtraLightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-ExtraLightItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-ExtraLightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight Italic"), local("IBMPlexSansCondensed-ExtraLightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight Italic"), local("IBMPlexSansCondensed-ExtraLightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight Italic"), local("IBMPlexSansCondensed-ExtraLightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Sans Condensed ExtraLight Italic"), local("IBMPlexSansCondensed-ExtraLightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ExtraLightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed Italic"), local("IBMPlexSansCondensed-Italic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Italic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed Italic"), local("IBMPlexSansCondensed-Italic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Italic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed Italic"), local("IBMPlexSansCondensed-Italic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed Italic"), local("IBMPlexSansCondensed-Italic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed Italic"), local("IBMPlexSansCondensed-Italic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light"), local("IBMPlexSansCondensed-Light"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Light.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light"), local("IBMPlexSansCondensed-Light"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Light-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light"), local("IBMPlexSansCondensed-Light"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light"), local("IBMPlexSansCondensed-Light"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light"), local("IBMPlexSansCondensed-Light"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light Italic"), local("IBMPlexSansCondensed-LightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-LightItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-LightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light Italic"), local("IBMPlexSansCondensed-LightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light Italic"), local("IBMPlexSansCondensed-LightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light Italic"), local("IBMPlexSansCondensed-LightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Condensed Light Italic"), local("IBMPlexSansCondensed-LightItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium"), local("IBMPlexSansCondensed-Medium"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Medium.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium"), local("IBMPlexSansCondensed-Medium"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Medium-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium"), local("IBMPlexSansCondensed-Medium"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium"), local("IBMPlexSansCondensed-Medium"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium"), local("IBMPlexSansCondensed-Medium"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium Italic"), local("IBMPlexSansCondensed-MediumItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-MediumItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-MediumItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium Italic"), local("IBMPlexSansCondensed-MediumItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-MediumItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium Italic"), local("IBMPlexSansCondensed-MediumItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-MediumItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium Italic"), local("IBMPlexSansCondensed-MediumItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-MediumItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Sans Condensed Medium Italic"), local("IBMPlexSansCondensed-MediumItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-MediumItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed"), local("IBMPlexSansCondensed"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Regular.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed"), local("IBMPlexSansCondensed"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Regular-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed"), local("IBMPlexSansCondensed"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed"), local("IBMPlexSansCondensed"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Condensed"), local("IBMPlexSansCondensed"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold"), local("IBMPlexSansCondensed-SemiBold"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold"), local("IBMPlexSansCondensed-SemiBold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold"), local("IBMPlexSansCondensed-SemiBold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold"), local("IBMPlexSansCondensed-SemiBold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold"), local("IBMPlexSansCondensed-SemiBold"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold Italic"), local("IBMPlexSansCondensed-SemiBoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-SemiBoldItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-SemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold Italic"), local("IBMPlexSansCondensed-SemiBoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold Italic"), local("IBMPlexSansCondensed-SemiBoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold Italic"), local("IBMPlexSansCondensed-SemiBoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans Condensed SemiBold Italic"), local("IBMPlexSansCondensed-SemiBoldItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-SemiBoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text"), local("IBMPlexSansCondensed-Text"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Text.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text"), local("IBMPlexSansCondensed-Text"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Text-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text"), local("IBMPlexSansCondensed-Text"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text"), local("IBMPlexSansCondensed-Text"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text"), local("IBMPlexSansCondensed-Text"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text Italic"), local("IBMPlexSansCondensed-TextItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-TextItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-TextItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text Italic"), local("IBMPlexSansCondensed-TextItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-TextItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text Italic"), local("IBMPlexSansCondensed-TextItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-TextItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text Italic"), local("IBMPlexSansCondensed-TextItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-TextItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Sans Condensed Text Italic"), local("IBMPlexSansCondensed-TextItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-TextItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin"), local("IBMPlexSansCondensed-Thin"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-Thin.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin"), local("IBMPlexSansCondensed-Thin"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin"), local("IBMPlexSansCondensed-Thin"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Thin-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin"), local("IBMPlexSansCondensed-Thin"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Thin-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin"), local("IBMPlexSansCondensed-Thin"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-Thin-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin Italic"), local("IBMPlexSansCondensed-ThinItalic"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff2/IBMPlexSansCondensed-ThinItalic.woff2") format("woff2"), url("../IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-ThinItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin Italic"), local("IBMPlexSansCondensed-ThinItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ThinItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin Italic"), local("IBMPlexSansCondensed-ThinItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ThinItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin Italic"), local("IBMPlexSansCondensed-ThinItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ThinItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Sans Condensed Thin Italic"), local("IBMPlexSansCondensed-ThinItalic"), url("../IBM-Plex-Sans-Condensed/fonts/split/woff2/IBMPlexSansCondensed-ThinItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Hebrew Bold"), local("IBMPlexSansHebrew-Bold"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Bold.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Hebrew ExtraLight"), local("IBMPlexSansHebrew-ExtraLight"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Hebrew Light"), local("IBMPlexSansHebrew-Light"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Light.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Hebrew Medium"), local("IBMPlexSansHebrew-Medium"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Medium.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Hebrew"), local("IBMPlexSansHebrew"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Regular.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Hebrew SemiBold"), local("IBMPlexSansHebrew-SemiBold"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Hebrew Text"), local("IBMPlexSansHebrew-Text"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Text.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Hebrew';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Hebrew Thin"), local("IBMPlexSansHebrew-Thin"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff2/IBMPlexSansHebrew-Thin.woff2") format("woff2"), url("../IBM-Plex-Sans-Hebrew/fonts/complete/woff/IBMPlexSansHebrew-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Devanagari Bold"), local("IBMPlexDevanagari-Bold"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Bold.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Devanagari ExtraLight"), local("IBMPlexDevanagari-ExtraLight"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Devanagari Light"), local("IBMPlexDevanagari-Light"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Light.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Devanagari Medium"), local("IBMPlexDevanagari-Medium"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Medium.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Devanagari"), local("IBMPlexDevanagari"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Regular.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Devanagari SemiBold"), local("IBMPlexDevanagari-SemiBold"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Devanagari Text"), local("IBMPlexDevanagari-Text"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Text.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Devanagari';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Devanagari Thin"), local("IBMPlexDevanagari-Thin"), url("../IBM-Plex-Devanagari/fonts/complete/woff2/IBMPlexDevanagari-Thin.woff2") format("woff2"), url("../IBM-Plex-Devanagari/fonts/complete/woff/IBMPlexDevanagari-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Thai Bold"), local("IBMPlexThai-Bold"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Bold.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Thai ExtraLight"), local("IBMPlexThai-ExtraLight"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Thai Light"), local("IBMPlexThai-Light"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Light.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Thai Medium"), local("IBMPlexThai-Medium"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Medium.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Thai"), local("IBMPlexThai"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Regular.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Thai SemiBold"), local("IBMPlexThai-SemiBold"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Thai Text"), local("IBMPlexThai-Text"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Text.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Thai';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Thai Thin"), local("IBMPlexThai-Thin"), url("../IBM-Plex-Thai/fonts/complete/woff2/IBMPlexThai-Thin.woff2") format("woff2"), url("../IBM-Plex-Thai/fonts/complete/woff/IBMPlexThai-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Thai Looped Bold"), local("IBMPlexSansThaiLooped-Bold"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Bold.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Sans Thai Looped ExtraLight"), local("IBMPlexSansThaiLooped-ExtraLight"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Thai Looped Light"), local("IBMPlexSansThaiLooped-Light"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Light.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Thai Looped Medium"), local("IBMPlexSansThaiLooped-Medium"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Medium.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans Thai Looped"), local("IBMPlexSansThaiLooped"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Regular.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans Thai Looped SemiBold"), local("IBMPlexSansThaiLooped-SemiBold"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Sans Thai Looped Text"), local("IBMPlexSansThaiLooped-Text"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Text.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Sans Thai Looped Thin"), local("IBMPlexSansThaiLooped-Thin"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff2/IBMPlexSansThaiLooped-Thin.woff2") format("woff2"), url("../IBM-Plex-Sans-Thai-Looped/fonts/complete/woff/IBMPlexSansThaiLooped-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Bold.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Bold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Bold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Serif Bold"), local("IBMPlexSerif-Bold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-BoldItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-BoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src: local("IBM Plex Serif Bold Italic"), local("IBMPlexSerif-BoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLight-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLight-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLight-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLight-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight"), local("IBMPlexSerif-ExtraLight"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLight-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-ExtraLightItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-ExtraLightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 200;
  src: local("IBM Plex Serif ExtraLight Italic"), local("IBMPlexSerif-ExtraLightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ExtraLightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Italic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Light.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-LightItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-LightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Medium.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Medium-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Medium-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-MediumItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-MediumItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-MediumItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-MediumItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-MediumItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-MediumItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 500;
  src: local("IBM Plex Serif Medium Italic"), local("IBMPlexSerif-MediumItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-MediumItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Regular.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-SemiBoldItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-SemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold Italic"), local("IBMPlexSerif-SemiBoldItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-SemiBoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Text.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Text-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Text-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-TextItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-TextItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-TextItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-TextItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-TextItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-TextItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 450;
  src: local("IBM Plex Serif Text Italic"), local("IBMPlexSerif-TextItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-TextItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-Thin.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Thin.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Thin-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Thin-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Thin-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Thin-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Serif Thin"), local("IBMPlexSerif-Thin"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-Thin-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/complete/woff2/IBMPlexSerif-ThinItalic.woff2") format("woff2"), url("../IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-ThinItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ThinItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ThinItalic-Pi.woff2") format("woff2");
  unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ThinItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ThinItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 100;
  src: local("IBM Plex Serif Thin Italic"), local("IBMPlexSerif-ThinItalic"), url("../IBM-Plex-Serif/fonts/split/woff2/IBMPlexSerif-ThinItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Arabic Bold"), local("IBMPlexArabic-Bold"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Bold.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 200;
  src: local("IBM Plex Arabic ExtraLight"), local("IBMPlexArabic-ExtraLight"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-ExtraLight.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-ExtraLight.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Arabic Light"), local("IBMPlexArabic-Light"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Light.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Arabic Medium"), local("IBMPlexArabic-Medium"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Medium.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Arabic"), local("IBMPlexArabic"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Regular.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Arabic SemiBold"), local("IBMPlexArabic-SemiBold"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-SemiBold.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 450;
  src: local("IBM Plex Arabic Text"), local("IBMPlexArabic-Text"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Text.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Arabic';
  font-style: normal;
  font-weight: 100;
  src: local("IBM Plex Arabic Thin"), local("IBMPlexArabic-Thin"), url("../IBM-Plex-Arabic/fonts/complete/woff2/IBMPlexArabic-Thin.woff2") format("woff2"), url("../IBM-Plex-Arabic/fonts/complete/woff/IBMPlexArabic-Thin.woff") format("woff"); }
